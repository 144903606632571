import React, { Component, lazy, Suspense } from 'react';
import { withRouter, Switch } from 'react-router-dom';
import lo_debounce from 'lodash/debounce';

import { redux } from 'services/redux';
import { me } from 'services/me';
import { track } from 'services/track';

import Route from 'components/Route';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Notification from 'components/Notification';
import ConfirmationModal from 'components/ConfirmationModal';
import RouteLoader from 'components/RouteLoader';

class App extends Component {
  /**
   * Send 'pageView' event to TagManager.
   * Debounce is used in order to prevent track redirects.
   */
  track_page = lo_debounce(() => {
    track.page_view();
  }, 1000);

  componentDidMount() {
    this.track_page();

    me.me_get();

    redux.once('auth.signin', (key, signin) => {
      if (signin) me.me_get();
    });
  }

  shouldComponentUpdate(next) {
    if (next.location !== this.props.location) {
      this.track_page();

      if (next.location.pathname.endsWith('email-sent')) return true;
    }

    return false;
  }

  render() {
    const { fetching } = this.props;

    return (
      <>
        <Header />

        {fetching ? (
          <RouteLoader />
        ) : (
          <Suspense fallback={<RouteLoader />}>
            <Switch>
              <Route
                path={`(.*)/email-sent`}
                component={lazy(() => import('pages/EmailSent'))}
                title="Check your inbox"
              />

              <Route path="/me" component={lazy(() => import('pages/UserDashboard'))} />

              <Route
                path="/referral/:code"
                component={lazy(() => import('pages/UserReferral'))}
                title="Referral"
              />

              <Route path="/" component={lazy(() => import('pages/UserAuthentication'))} />
            </Switch>
          </Suspense>
        )}

        <Footer />

        <Notification />

        <ConfirmationModal />
      </>
    );
  }
}

export default redux.connect(withRouter(App), {
  fetching: 'me.me.fetching',
});
