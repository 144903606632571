import { api } from 'services/api';
import { redux } from 'services/redux';
import { notification } from 'services/notification';
import { track } from 'services/track';
import lo_debounce from 'lodash/debounce';

class Auth {
  constructor() {
    this.check();
  }

  set_token = (access) => {
    localStorage.setItem('token', access);
    redux.action('token', access);
    redux.action('auth.signin', true);
  };

  check = () => {
    const token = localStorage.getItem('token');
    if (token) redux.action('token', token);
    else redux.action('token', null);
  };

  signup = async ({ name, email, password, referral_code = undefined }) => {
    if (!name) return notification.warning('Please enter a valid name');
    if (!email || email.length < 4) return notification.warning('Please enter a valid email');
    if (!password || password.length < 4)
      return notification.warning('Please enter a valid password');
    if (referral_code && referral_code.length < 80)
      return notification.warning('Referral code is not valid');

    redux.action('auth.signup.fetching', true);

    const { json, status } = await api.post_auth_signup({ name, email, password, referral_code });

    if (status === 200) {
      this.set_token(json.access);
      redux.action('auth.signup', { name, email, password, referral_code });
      track.lead();
    } else if (status === 409) notification.warning('This e-mail is already registered');
    else notification.danger('Unable to signup');

    redux.action('auth.signup.fetching', false);
  };

  signin = async ({ email, password }) => {
    if (!email || email.length < 4) return notification.warning('Please enter a valid email');
    if (!password || password.length < 4)
      return notification.warning('Please enter a valid password');

    redux.action('auth.signin.fetching', true);

    const { json, status } = await api.post_auth_signin({ email, password });

    if (status === 200) {
      this.set_token(json.access);
    } else notification.warning('Invalid email or password');

    redux.action('auth.signin.fetching', false);
  };

  signout = async () => {
    const state = redux.state();

    if (state['me.me.fetching']) return;

    redux.action('auth.signout.fetching', true);

    const { status } = await api.post_auth_signout();

    if (status === 204 || status === 401) {
      localStorage.removeItem('token');
      redux.reset();
    } else {
      notification.danger('Could not logout');
      redux.action('auth.signout.fetching', false);
    }
  };

  confirm = async ({ token }) => {
    if (!token) {
      redux.action('auth.confirm', false);
      return notification.warning('The token to confirm password is not valid');
    }

    redux.action('auth.confirm.fetching', true);

    const { status } = await api.post_auth_confirm({ token });

    if (status === 200) {
      redux.action('auth.confirm', true);
      track.complete_registration();
    } else notification.danger('Unable to confirm your email address');

    redux.action('auth.confirm.fetching', false);
  };

  resend = async ({ email }) => {
    if (!email || email.length < 4) return notification.warning('Please enter a valid email');

    redux.action('auth.resend.fetching', true);

    const { status } = await api.post_auth_resend({ email });

    if (status === 200) {
      redux.action('auth.resend', true);

      setTimeout(() => {
        redux.action('auth.resend', false);
      }, 8000);
    } else notification.danger('Unable to resend a confirmation email');

    redux.action('auth.resend.fetching', false);
  };

  reset = async ({ password, password_confirm, token }) => {
    if (!token) return notification.warning('The token to reset password is not valid');
    if (!password || password.length < 4)
      return notification.warning('Please enter a valid password');
    if (password !== password_confirm) return notification.warning('Passwords do not match');

    redux.action('auth.reset.fetching', true);

    const { json, status } = await api.post_auth_reset({ password, token });

    if (status === 200) redux.action('auth.reset', true);
    else if (status === 400 && json.errors.token) notification.warning('Invalid link');
    else notification.warning('Invalid password');

    redux.action('auth.reset.fetching', false);
  };

  recover = async ({ email }) => {
    if (!email || email.length < 4) return notification.warning('Please enter a valid email');

    redux.action('auth.recover.fetching', true);

    const { status } = await api.post_auth_recover({ email });

    if (status === 200) {
      redux.action('auth.recover', { email });
      redux.action(
        'email.sent.message',
        `We have sent an e-mail to ${email} with a link to modify your password.`
      );
    } else notification.warning('Invalid password');

    redux.action('auth.recover.fetching', false);
  };
}

const auth = new Auth();

export { auth };
