import { redux } from 'services/redux';

class Notification {
  info = (message, timeout = undefined) =>
    redux.action('notification', { message, level: 'is-info', timeout });

  warning = (message, timeout = undefined) =>
    redux.action('notification', { message, level: 'is-warning', timeout });

  danger = (message, timeout = undefined) =>
    redux.action('notification', { message, level: 'is-danger', timeout });

  success = (message, timeout = undefined) =>
    redux.action('notification', { message, level: 'is-success', timeout });

  clear = () => redux.action('notification', null);
}

const notification = new Notification();

export { notification };
