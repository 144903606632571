import lo_debounce from 'lodash/debounce';

import { api } from 'services/api';
import { auth } from 'services/auth';
import { redux } from 'services/redux';
import { notification } from 'services/notification';
import { payment_source } from 'services/payment-source';

class Me {
  me_clear = () => {
    redux.action('me.me', undefined);
    return true;
  };

  me_get = async () => {
    redux.action('me.me.fetching', true);

    lo_debounce(async () => {
      const { json, status } = await api.get_me();

      if (status === 200) redux.action('me.me', json);
      else if (status === 401) redux.action('token', null);
      else notification.danger('Unable to fetch your account');

      redux.action('me.me.fetching', false);
    }, 200)();
  };

  me_update = async ({ name, company, email }) => {
    if (name === undefined && company === undefined && email === undefined)
      return notification.warning('Nothing to update');

    redux.action('me.me.fetching', true);

    const { json, status } = await api.patch_me({ name, company, email });

    if (status === 200) {
      redux.action('me.me', json);
      redux.action('me.update', true);

      if (email) notification.info('Check your new email address to complete the request.');
    } else if (status === 409) notification.warning('This e-mail is already registered');
    else notification.danger('Unable to update your account');

    redux.action('me.me.fetching', false);
    return;
  };

  email_update = async ({ token }) => {
    redux.action('me.email_update.fetching', true);

    const { json, status } = await api.post_me_email_confirm({ token });

    if (status === 200) {
      this.me_get();
      notification.info('Your email address has been successfully updated');
    } else notification.danger('Unable to update your email');

    redux.action('me.email_update.fetching', false);
    return;
  };

  picture_get = async () => {
    redux.action('me.picture.fetching', true);

    const { blob, status } = await api.get_me_picture();

    if (status === 200) redux.action('me.picture', URL.createObjectURL(blob));
    else if (status === 404) void 0;
    else notification.warning('Unable to fetch your picture');

    redux.action('me.picture.fetching', false);
  };

  picture_update = async (form) => {
    redux.action('me.picture.fetching', true);

    const { status } = await api.post_me_picture(form);

    if (status === 202) this.picture_get();
    else notification.danger('Unable to update your picture');

    redux.action('me.picture.fetching', false);
  };

  invoices = async () => {
    redux.action('me.invoices.fetching', true);

    const { json, status } = await api.get_me_invoices();

    if (status === 200) redux.action('me.invoices', json.invoices);
    else notification.warning('Unable to retrive your invoices');

    redux.action('me.invoices.fetching', false);
  };

  passes = async () => {
    redux.action('me.passes.fetching', true);

    const { json, status } = await api.get_me_passes();

    if (status === 200) redux.action('me.passes', json);
    else notification.warning('Unable to fetch your daily passes');

    redux.action('me.passes.fetching', false);
  };

  password = async ({ old_password, new_password, new_password_confirm }) => {
    if (!new_password || new_password.length < 4)
      return notification.warning('Please enter a valid password');
    if (new_password !== new_password_confirm)
      return notification.warning('Passwords do not match');

    redux.action('me.password.fetching', true);

    const { status } = await api.post_auth_password({ old_password, new_password });

    if (status === 200) redux.action('me.password', true);
    else notification.danger('Unable to update your password');

    redux.action('me.password.fetching', false);
  };

  delete = async () => {
    const on_confirm = async (key, confirmed) => {
      if (!confirmed) return;

      redux.action('me.delete.fetching', true);

      const { status } = await api.delete_me();

      if (status === 204) {
        auth.signout();
        setTimeout(() => {
          notification.info('Your account has been deleted');
        });
      } else if (status === 403)
        redux.action(
          'notification',
          'To delete your account access your AppleID and revoke access'
        );
      else notification.danger('Unable to delete your account');

      redux.action('me.delete.fetching', false);
    };

    redux.once('modal.confirm', on_confirm);

    redux.action('modal', {
      title: 'Are you sure you want to delete your account?',
      message: `Once you delete your account, there is no going back.`,
      status: 'danger',
    });
  };

  customer_update = async ({ address, tax, name }) => {
    redux.action('me.customer.fetching', true);

    const { json, status } = await api.patch_me_customer({ address, tax, name });

    if (status === 200) redux.action('me.customer', json);
    else if (status === 400 && json.message) notification.warning(json.message);
    else notification.danger('Unable to update your billing details');

    redux.action('me.customer.fetching', false);
  };

  transfer_pass = async ({ to }) => {
    if (!to?.id) return notification.warning('You must select a contact first');

    let { 'me.passes': my_passes } = redux.state();

    const pass = my_passes.find((p) => p.status === 'ok');

    if (!pass?.id) return notification.warning("You don't have enough passes");

    redux.action('me.transfer_pass.fetching', true);

    const { json, status } = await api.post_me_passes_id_transfer({
      pass_id: pass.id,
      to_id: to.id,
    });

    if (status === 200) {
      redux.action('me.transfer_pass', true);
      notification.success(`Pass has been transferred to ${to.contact.email}`);
      me.passes();
    } else notification.danger('Unable to transfer pass');

    redux.action('me.transfer_pass.fetching', false);
  };

  plan = async () => {
    const set_plan = (key, value) => {
      let { 'me.me': me, 'products.all': products } = redux.state();

      if (key === 'me.me') me = value;
      else if (key === 'products.all') products = value;

      if (me && products) {
        const membership = me.membership || {};
        const me_plan = products.find(
          (plan) => plan.params.membership && plan.params.membership === membership.type
        );
        redux.action('me.plan', me_plan || null);
      }
    };

    redux.on('me.me', set_plan);
    redux.on('products.all', set_plan);
  };

  contacts = async () => {
    redux.action('me.contacts.fetching', true);

    const { json, status } = await api.get_me_contacts();

    if (status === 200) redux.action('me.contacts', json);
    else notification.warning('Unable to fetch your contacts');

    redux.action('me.contacts.fetching', false);
  };

  realtime() {
    const onmessage = ({ data }) => {
      const { name, action } = JSON.parse(data);

      if (action === 'created') return;

      if (name === 'tokens') this.me_get();
      else if (name === 'users') {
        this.me_get();
        payment_source.payment_source();
      } else if (name === 'passes') this.me_get();
      else if (name === 'purchases') {
        this.passes();
        this.invoices();
      } else if (name === 'referrals') this.me_get();
    };

    api.realtime({ onmessage });
  }
}

const me = new Me();

export { me };
