import React from 'react';
import { BarLoader } from 'react-spinners';

const sectionStyle = {
  height: '100%',
  marginTop: '4em',
};

const loadStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '70%',
};

const RouteLoader = () => (
  <section className="section" style={sectionStyle}>
    <div className="container has-text-centered" style={loadStyle}>
      <div className="columns is-centered">
        <div className="column is-narrow">
          <BarLoader color={'#222222'} loading={true} />
        </div>
      </div>
    </div>
  </section>
);

export default RouteLoader;
