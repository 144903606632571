import React from 'react';
import { Link } from 'react-router-dom';

const footerStyle = {
  width: '100%',
  margin: '0em',
  padding: '3em 0em 2em 0em',
};

const Footer = () => (
  <footer className="footer" style={footerStyle}>
    <style>{`.link:hover{ text-decoration: underline; }`}</style>
    <div className="container">
      <div className="columns is-size-7" style={{ justifyContent: 'space-around' }}>
        <div className="column is-one-quarter has-text-left has-text-centered-mobile">
          <Link className="link" to="/">
            © ConnectionOpen {new Date().getFullYear()}
          </Link>
        </div>
        <div className="column"></div>
        <div className="column is-one-quarter has-text-right has-text-centered-mobile"></div>
      </div>
    </div>
  </footer>
);

export default Footer;
