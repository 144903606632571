import React, { PureComponent } from 'react';
import { Route as RouterRoute } from 'react-router-dom';

class Route extends PureComponent {
  set_title = () => {
    const { title } = this.props;

    if (title) document.title = `${title} | Connection Open`;
  };

  componentDidMount() {
    this.set_title();
  }

  componentDidUpdate() {
    this.set_title();
  }

  render() {
    const { component, path } = this.props;

    return <RouterRoute path={path} component={component} />;
  }
}

export default Route;
