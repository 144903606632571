import { createStore } from 'redux';
import { connect } from 'react-redux';
import lo_mapValues from 'lodash/mapValues';
import lo_get from 'lodash/get';

class Redux {
  listeners = new Map();
  initialState = {
    token: localStorage.getItem('token'),
  };

  constructor() {
    const devtools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
    this.store = createStore(this.reducer, this.initialState, devtools);

    if (process.env.NODE_ENV !== 'production') {
      Object.assign(window, { redux: this });
    }
  }

  reducer = (state = {}, { type, payload }) => {
    if (type === '_reset_') return { notification: state.notification };

    return { ...state, [type]: payload };
  };

  reset = () => {
    this.store.dispatch({ type: '_reset_' });
  };

  action = (key, value) => {
    // console.log('ACTION', key, value);

    for (const cb of this.listeners.get(key) || []) {
      cb(key, value);
    }

    this.store.dispatch({ type: key, payload: value });
  };

  connect = (Component, props) =>
    connect((state) => lo_mapValues(props, (key_in_state) => lo_get(state, key_in_state)))(
      Component
    );

  state = () => {
    const state = this.store.getState();
    return state;
  };

  once(key, cb) {
    this.listeners.set(key, [cb]);
  }

  on(key, cb) {
    const listenersForKey = this.listeners.get(key) || [];
    this.listeners.set(key, listenersForKey.concat(cb));
  }

  off(key, cb) {
    const listenersForKey = (this.listeners.get(key) || []).filter((listener) => listener !== cb);
    this.listeners.set(key, listenersForKey);
  }
}

const redux = new Redux();

export { redux };
