import TagManager from 'react-gtm-module';

class Track {
  constructor() {
    this.gtm('initialize', { gtmId: 'GTM-WQ7D3ZN' });
  }

  gtm = (method, ...args) => {
    if (process.env.NODE_ENV === 'production') {
      TagManager[method](...args);
    } else {
      console.log('TRACK', method, ...args);
    }
  };

  download = ({ product }) => {
    this.gtm('dataLayer', { dataLayer: { event: 'Download', product: product.name } });
  };

  add_payment_info = () => {
    this.gtm('dataLayer', { dataLayer: { event: 'AddPaymentInfo' } });
  };

  complete_registration = () => {
    this.gtm('dataLayer', { dataLayer: { event: 'CompleteRegistration' } });
  };

  initiate_checkout = ({ product }) => {
    this.gtm('dataLayer', {
      dataLayer: { event: 'InitiateCheckout', product: product.name, value: Number(product.price) },
    });
  };

  // Signup. Facebook refers as lead
  lead = () => {
    this.gtm('dataLayer', { dataLayer: { event: 'Lead' } });
  };

  // Only valid for passes
  purchase = ({ product }) => {
    this.gtm('dataLayer', {
      dataLayer: { event: 'Purchase', product: product.name, value: Number(product.price) },
    });
  };

  // Only valid for subscriptions
  start_trial = ({ product }) => {
    this.gtm('dataLayer', {
      dataLayer: { event: 'StartTrial', product: product.name, value: Number(product.price) },
    });
  };

  subscribe = ({ product, date_ms }) => {
    this.gtm('dataLayer', {
      dataLayer: { event: 'Subscribe', product: product.name, value: Number(product.price) },
    });
  };

  page_view = () => {
    this.gtm('dataLayer', { dataLayer: { event: 'PageView' } });
  };
}

const track = new Track();

export { track };
