import React, { PureComponent } from 'react';

import { redux } from 'services/redux';

import { ReactComponent as SuccessIcon } from 'assets/check.svg';
import { ReactComponent as DangerIcon } from 'assets/danger.svg';

export class ConfirmationModal extends PureComponent {
  status = ['success', 'danger'];

  close = () => {
    redux.action('modal', null);
  };

  on_confirm = () => {
    redux.action('modal.confirm', true);
    this.close();
  };

  on_cancel = () => {
    redux.action('modal.confirm', false);
    this.close();
  };

  render() {
    const { status = 'success', title, message } = this.props || {};

    return (
      <div className={`modal ${title ? 'is-active' : ''}`}>
        <div className="modal-background" style={{ opacity: '.4' }}></div>
        <div
          className="modal-card"
          style={{
            boxShadow: '0px 9px 20px #0005',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
          }}
        >
          <section className="modal-card-body has-text-centered">
            <div
              className="is-flex is-stacked"
              style={{
                height: '15rem',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <figure
                className={`image is-64x64 has-text-${status === 'success' ? 'primary' : 'danger'}`}
              >
                {status === 'success' ? <SuccessIcon /> : <DangerIcon />}
              </figure>

              <p className="is-size-4 has-text-weight-bold">{title}</p>
              <p className="is-size-6" style={{ whiteSpace: 'pre-wrap' }}>
                {message}
              </p>
            </div>
          </section>
          <footer className={`modal-card-foot columns is-radiusless is-marginless is-centered`}>
            <div className="column is-6 columns is-centered is-mobile">
              <button
                className={`is-paddingless button column is-5 is-rounded is-inverted is-secondary`}
                onClick={this.on_cancel}
              >
                Cancel
              </button>

              <div className="column is-2"></div>
              <button
                className={`is-paddingless button column is-5 is-rounded is-${
                  status === 'success' ? 'primary' : 'danger'
                }`}
                onClick={this.on_confirm}
              >
                Confirm
              </button>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default redux.connect(ConfirmationModal, {
  status: 'modal.status',
  title: 'modal.title',
  message: 'modal.message',
});
