import React from 'react';

const imgWrapperStyle = (hasPicture, isBig) => ({
  width: isBig ? '8em' : '40px',
  height: isBig ? '8em' : '40px',
  borderRadius: '50%',
  overflow: 'hidden',
  border: hasPicture ? 'none' : '1px solid #bbbcbe',
  display: 'flex',
  justifyContent: 'center',
  marginRight: isBig ? '0' : '10px',
});

const imageStyle = {
  objectFit: 'cover',
  objectPosition: 'center right',
  maxHeight: 'fit-content',
};

const RoundedPicture = ({ picture, size = 'small' }) => {
  const isBig = size === 'big';

  return (
    <div className="is-rounded" style={imgWrapperStyle(picture, isBig)}>
      {picture ? (
        <img
          className={picture ? 'fillheight fillwidth' : 'is-hidden'}
          style={imageStyle}
          src={picture}
          alt="Profile"
        />
      ) : null}
      <div className={picture ? 'is-hidden' : ''} style={{ fontSize: isBig ? '7.75em' : '38px' }}>
        <span className="icon">
          <i className="fas fa-user"></i>
        </span>
      </div>
    </div>
  );
};

export default RoundedPicture;
