window.website_url = process.env.REACT_APP_LANDING_URL || 'https://beta.connectionopen.com';
window.api_url = process.env.REACT_APP_API_BASE_URL || 'https://api-dev.connectionopen.com';
window.downloads_url =
  process.env.REACT_APP_DOWNLOADS_URL || 'https://downloads.connectionopen.com';
window.stripe_key = process.env.REACT_APP_STRIPE_KEY || 'pk_test_uhg9gYvstDhA6x5CrpXYZ1DD';
window.prismic_url =
  process.env.REACT_APP_PRISMIC_URL || 'https://connectionopen.cdn.prismic.io/api/v2';

export default {
  trial: {
    duration: 7,
    unit: 'days',
  },
};
