import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { redux } from 'services/redux';
import { me } from 'services/me';

import RoundedPicture from 'components/RoundedPicture';

const labelStyle = {
  fontFamily: "'Roboto Mono', monospace",
  margin: '5px 0 5px 15px',
};

const dropdownStyle = {
  minWidth: '200px',
  borderRadius: '2px',
};

class Header extends PureComponent {
  state = { burguerOpened: false };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({ burguerOpened: false });
    }
  }

  toggleBurguer = (e) => {
    e.preventDefault();
    this.setState({ burguerOpened: !this.state.burguerOpened });
    return false;
  };

  render_not_authenticated = () => (
    <div className="navbar-end is-inline-block-touch is-flex-desktop">
      <Link className="navbar-item" to="/login">
        <div className="button is-rounded is-outlined is-dark is-fullwidth">Login</div>
      </Link>
      <Link className="navbar-item" to="/signup">
        <div className="button is-primary is-rounded is-fullwidth">Signup</div>
      </Link>
    </div>
  );

  render_authenticated = () => (
    <div className="navbar-end">
      <div className="navbar-item has-dropdown is-hoverable">
        <button
          className="navbar-link has-text-black is-flex is-borderless"
          style={{ alignItems: 'center', background: 'none' }}
        >
          <RoundedPicture picture={this.props.picture} />
          <span>{this.props.me.name}</span>
        </button>
        <div className="navbar-dropdown is-boxed is-inline-block" style={dropdownStyle}>
          <div className="field" style={labelStyle}>
            <label className="label is-small">MY PROFILE</label>
          </div>
          <Link className="navbar-item has-text-black" to="/me/home">
            Home
          </Link>
          <Link className="navbar-item has-text-black" to="/me/profile">
            Profile
          </Link>
          <Link className="navbar-item has-text-black" to="/me/billing">
            Billing
          </Link>
          <Link className="navbar-item has-text-black" to="/me/download">
            Download
          </Link>
          <hr className="navbar-divider" />
          <Link className="navbar-item has-text-danger" to="/login" onClick={me.me_clear}>
            <div className="button is-rounded is-outlined is-dark is-fullwidth">Logout</div>
          </Link>
        </div>
      </div>
    </div>
  );

  render() {
    const { me = {} } = this.props;

    return (
      <header className="header">
        <nav className="navbar is-fixed-top is-transparent">
          <div className="container">
            <div className="navbar-brand">
              <a className="navbar-item" href="https://www.connectionopen.com">
                <img src="/images/logo.svg" alt="Connection Open" />
              </a>
              <a
                href="#!"
                role="button"
                className={`navbar-burger ${this.state.burguerOpened ? 'is-active' : ''}`}
                style={{ color: 'var(--color-grey-dark)' }}
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarContent"
                onClick={this.toggleBurguer}
              >
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </a>
            </div>
            <div
              id="navbarContent"
              className={`navbar-menu ${this.state.burguerOpened ? 'is-active' : ''}`}
            >
              {!me.id ? this.render_not_authenticated() : this.render_authenticated()}
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default withRouter(
  redux.connect(Header, {
    me: 'me.me',
    picture: 'me.picture',
  })
);
