import { redux } from 'services/redux';

class Api {
  async request(path, { method = 'GET', body = undefined, auth = true, query = null } = {}) {
    const url = new URL(path, window.api_url);
    const controller = new window.AbortController();
    const signal = controller.signal;
    const headers = { Authorization: '', 'Content-Type': '' };

    let status = 0;
    let json = null;
    let blob = null;

    if (auth) {
      const { token } = redux.state();
      headers['Authorization'] = `Bearer ${token}`;
    }

    if (query) {
      for (const key in query) {
        if (query[key]) url.searchParams.append(key, query[key]);
      }
    }

    if (body instanceof FormData) {
      delete headers['Content-Type'];
    } else {
      headers['Content-Type'] = 'application/json';
      body = JSON.stringify(body);
    }

    setTimeout(() => controller.abort(), 10000);

    const r = await fetch(url.toString(), { method, body, headers, signal });
    status = r.status;

    if ((r.headers.get('Content-Type') || '').startsWith('application/json'))
      json = await r.json().catch(() => null);
    else blob = await r.blob().catch(() => null);

    // console.log({ type: 'FETCH', url, body, json, blob, status, r });

    return { status, json, blob };
  }

  request_no_auth = (path, { method, body }) => this.request(path, { method, body, auth: false });

  get_me = ({ email: user_email } = { email: null }) =>
    this.request('/me', { query: { user_email } });

  get_me_detail = ({ email: user_email } = { email: null }) =>
    this.request('/me/detail', { query: { user_email } });

  get_me_picture = () => this.request('/me/picture');

  get_me_invoices = () => this.request('/me/invoices');

  get_me_payment_sources = () => this.request('/me/payment_sources');

  get_products = () => this.request('/products');

  get_me_passes = () => this.request('/me/passes');

  get_me_contacts = () => this.request('/me/contacts');

  get_coupons_code = ({ code, product_id }) =>
    this.request(`/products/${product_id}/coupons/${code}`);

  get_admin_user = ({ email }) => this.request('/admin/user', { query: { email } });

  get_stats_users_in_session = () => this.request('/stats/users/in-session');

  get_stats_users_online = () => this.request('/stats/users/online');

  get_me_subscription_preview = ({ product_id, date_ms, coupon_code = '' }) =>
    this.request('/me/subscription/preview', {
      query: {
        product_id,
        date_ms,
        coupon_code,
      },
    });

  patch_me = ({ name, company, email }) =>
    this.request('/me', { method: 'PATCH', body: { name, company, email } });

  post_me_email_confirm = ({ token }) =>
    this.request('/me/email/confirm', { method: 'POST', body: { token } });

  patch_me_customer = ({ address, tax, name }) =>
    this.request('/me/customer', { method: 'PATCH', body: { address, tax, name } });

  patch_admin_subscription_passes = ({ email, passes }) =>
    this.request('/admin/subscription/passes', {
      method: 'PATCH',
      body: { email, passes },
    });

  delete_me = () => this.request('/me', { method: 'DELETE' });

  delete_me_payment_sources = ({ source }) =>
    this.request(`/me/payment_sources/${source}`, { method: 'DELETE' });

  post_auth_signin = ({ email, password }) =>
    this.request_no_auth('/auth/signin', { method: 'POST', body: { email, password } });

  post_auth_signout = () => this.request('/auth/signout', { method: 'POST', body: {} });

  post_auth_password = ({ old_password, new_password }) =>
    this.request('/auth/password', { method: 'POST', body: { old_password, new_password } });

  post_auth_reset = ({ password, token }) =>
    this.request_no_auth('/auth/reset', { method: 'POST', body: { password, token } });

  post_auth_recover = ({ email }) =>
    this.request_no_auth('/auth/recover', { method: 'POST', body: { email } });

  post_auth_confirm = ({ token }) =>
    this.request_no_auth('/auth/confirm', { method: 'POST', body: { token } });

  post_auth_resend = ({ email }) =>
    this.request_no_auth('/auth/resend', { method: 'POST', body: { email } });

  post_auth_signup = ({ name, password, email, referral_code = undefined }) =>
    this.request_no_auth('/auth/signup', {
      method: 'POST',
      body: { name, password, email, referral_code },
    });

  post_referral_check = ({ code }) =>
    this.request_no_auth('/referral/check', { method: 'POST', body: { code } });

  post_me_picture = (form) => this.request('/me/picture', { method: 'POST', body: form });

  post_me_payment_sources = ({ source }) =>
    this.request('/me/payment_sources', { method: 'POST', body: { source } });

  post_me_purchases = ({ product_id, coupon_code = null, date_ms = null }) =>
    this.request('/me/purchases', { method: 'POST', body: { product_id, coupon_code, date_ms } });

  post_me_membership_cancel = () =>
    this.request('/me/membership/cancel', { method: 'POST', body: {} });

  post_me_membership_reactivate = () =>
    this.request('/me/membership/reactivate', { method: 'POST', body: {} });

  post_me_passes_id_transfer = ({ pass_id, to_id }) =>
    this.request(`/me/passes/${pass_id}/transfer`, { method: 'POST', body: { to: to_id } });

  post_admin_passes_add = ({ email, passes: count }) =>
    this.request('/admin/passes/add', { method: 'POST', body: { email, count } });

  post_admin_passes_remove = ({ email, passes: count }) =>
    this.request('/admin/passes/remove', { method: 'POST', body: { email, count } });

  post_stats_users = ({
    great_than,
    less_than,
    resolution,
    status = undefined,
    trialing = undefined,
    membership = undefined,
    membership_status = undefined,
  }) =>
    this.request('/stats/users', {
      method: 'POST',
      body: { great_than, less_than, resolution, status, trialing, membership, membership_status },
    });

  post_stats_users_sessions = ({ great_than, less_than, resolution, email, unique }) =>
    this.request('/stats/users-sessions', {
      method: 'POST',
      body: { great_than, less_than, resolution, email, unique },
    });

  post_stats_users_sessions_top = ({ great_than, less_than, limit }) =>
    this.request('/stats/users-sessions/top', {
      method: 'POST',
      body: { great_than, less_than, limit },
    });

  post_stats_version = ({ great_than, less_than, resolution }) =>
    this.request('/stats/version', {
      method: 'POST',
      body: { great_than, less_than, resolution },
    });

  post_stats_sessions = ({ great_than, less_than, resolution }) =>
    this.request('/stats/sessions', {
      method: 'POST',
      body: { great_than, less_than, resolution },
    });

  post_me_contacts_top = ({ great_than, less_than, email: user_email }) =>
    this.request(`/me/contacts/top`, {
      method: 'POST',
      body: { great_than, less_than },
      query: { user_email },
    });

  post_stats_os = ({ great_than, less_than, resolution }) =>
    this.request('/stats/os', {
      method: 'POST',
      body: { great_than, less_than, resolution },
    });

  post_me_stats_sessions = ({ great_than, less_than, resolution }) =>
    this.request('/me/stats/sessions', {
      method: 'POST',
      body: { great_than, less_than, resolution },
    });

  post_stats_users_sessions_score = ({ great_than, less_than, resolution, version = undefined }) =>
    this.request('/stats/users-sessions-score', {
      method: 'POST',
      body: { great_than, less_than, resolution, version },
    });

  post_me_sessions_metrics_info = ({
    great_than,
    less_than,
    page = 1,
    pageSize = 5,
    email: user_email = undefined,
  }) =>
    this.request('/me/sessions/metrics/info', {
      method: 'POST',
      body: { great_than, less_than, page, pageSize },
      query: { user_email },
    });

  realtime({ onmessage }) {
    const { token } = redux.state();
    const url = new URL(window.api_url);
    url.protocol = url.protocol === 'https:' ? 'wss:' : 'ws:';
    url.pathname = '/me/realtime';
    url.search = `token=${token}`;

    const ws = new WebSocket(url.toString());

    if (onmessage) ws.onmessage = onmessage;
  }
}

const api = new Api();

export { api };
