import { api } from 'services/api';
import { redux } from 'services/redux';
import { notification } from 'services/notification';
import { track } from 'services/track';

class PaymentSource {
  payment_source = async () => {
    redux.action('payment_source.payment_source.fetching', true);

    const { json, status } = await api.get_me_payment_sources();

    if (status === 200) redux.action('payment_source.payment_source', json.sources.pop());
    else notification.warning('Unable to fetch your card');

    redux.action('payment_source.payment_source.fetching', false);
  };

  add = async ({
    createToken,
    name,
    address_line1,
    address_city,
    address_state,
    address_zip,
    address_country,
  }) => {
    redux.action('payment_source.add.fetching', true);

    const { token } = await createToken({
      name,
      address_line1,
      address_city,
      address_state,
      address_zip,
      address_country,
    }).catch(() => ({}));

    if (!token) return notification.danger('Unable to attach your card');

    const { status } = await api.post_me_payment_sources({ source: token.id });

    if (status === 200) {
      redux.action('payment_source.add', true);
      track.add_payment_info();
    } else notification.danger('Unable to add your card');

    redux.action('payment_source.add.fetching', false);
  };

  delete = ({ source }) => {
    const on_confirm = async (key, confirmed) => {
      if (!confirmed) return;

      redux.action('payment_source.delete.fetching', true);

      const { status } = await api.delete_me_payment_sources({ source });

      if (status === 200) redux.action('payment_source.delete', true);
      else notification.danger('Unable to delete your card');

      redux.action('payment_source.delete.fetching', false);
    };

    redux.once('modal.confirm', on_confirm);

    redux.action('modal', {
      title: 'Delete card',
      message: `Are you sure you want to delete your card?`,
      status: 'danger',
    });
  };
}

const payment_source = new PaymentSource();

export { payment_source };
