import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import 'polyfills';
import 'config';
import App from 'App';
import { unregister } from 'registerServiceWorker';
import { redux } from 'services/redux';
import 'services/track';
import 'scss/bulma.scss';
import 'index.css';

const WrapperApp = () => (
  <Provider store={redux.store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(<WrapperApp />, document.getElementById('root'));

unregister();
