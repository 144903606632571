import React, { PureComponent } from 'react';

import { redux } from 'services/redux';
import { notification } from 'services/notification';

import lo_debounce from 'lodash/debounce';

class Notification extends PureComponent {
  clear_notification = () => {
    notification.clear();
  };

  componentDidUpdate() {
    const { notification } = this.props;

    if (notification) {
      const { timeout = 6000 } = notification;

      lo_debounce(this.clear_notification, timeout)();
    }
  }

  render() {
    const { notification } = this.props;
    const { message, level } = notification || {};

    const transform = notification ? 'translateY(0)' : 'translateY(-100%)';

    return (
      <div
        className="columns is-marginless"
        style={{
          position: 'fixed',
          zIndex: 29,
          top: '6rem',
          left: '0',
          right: '0',
          transform,
          transition: 'all 200ms',
        }}
      >
        <div className="column is-paddingless">
          <div className={`notification is-radiusless ${level}`}>
            <div className="container">
              <div className="columns is-mobile">
                <span className="column">{message}</span>

                <div className="column is-narrow">
                  <button className="delete" onClick={this.clear_notification}></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default redux.connect(Notification, {
  notification: 'notification',
});
